import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InviteInfo } from "./ApiService";
import { fetchTestInfo, PageState, waitFor } from "./utils";
import { CollectInfo } from "./CollectInfo";
import { Spinner } from "./Spinner";
import { Ended } from "./Ended";
import { FinishedPage } from "./FinishedPage";
import { QuestionsPage } from "./QuestionsPage";
import { ShowInfoAboutTest } from "./ShowInfoAboutTest";

export const AssessmentPage = () => {
  // Possible States
  // unknown -> means fetching info from backend and showing spinner
  // nouser -> collect email and name of user show form
  // inforeceived -> show info about test like number of questions and clicking next will start the timer
  // inprogress -> Show current quest
  // finished -> show test already submitted
  // ended -> show test duration ended before completing
  const [state, setState] = useState<PageState>("unknown");
  const [info, setTestInfo] = useState<InviteInfo | null>(null);
  const { assessmentId } = useParams<{ assessmentId: string }>();

  const navigate = useNavigate();
  const onLoad = async () => {
    if (!assessmentId) {
      // Go to 404
      navigate("/404");
      return;
    }
    const [info] = await Promise.all([
      fetchTestInfo(assessmentId),
      waitFor(1000),
    ]);
    setState(info.status);
    setTestInfo(info.info);
  };

  const onNameAdd = async () => {
    await onLoad();
  };
  const startAssessment = async () => {
    setState("inprogress");
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      {state === "unknown" && <Spinner />}
      {state === "nouser" && <CollectInfo onSuccess={onNameAdd} />}
      {state === "inforeceived" && (
        <ShowInfoAboutTest
          startAssessment={startAssessment}
          info={info as InviteInfo}
        />
      )}
      {state === "inprogress" && (
        <QuestionsPage
          setStatus={setState}
          setInfo={setTestInfo}
          info={info as InviteInfo}
        />
      )}
      {state === "finished" && <FinishedPage />}
      {state === "ended" && <Ended />}
    </main>
  );
};
