import axios from "axios";

export type InviteInfo = {
  duration: number;
  assessment_start_time: string | null;
  assessment_complete_time: string | null;
  name: string | null;
  invite_id: string;
  count: number;
};
export type IQuestion = {
  id: number;
  submitted_ans: string | null | number;
  question: {
    id: number;
    question: string;
    Option: { id: number; value: string }[];
  };
};

export const ApiService = (() => {
  const api = axios.create({
    baseURL:
      process.env.NODE_ENV === "development"
        ? "http://localhost:1337"
        : "https://mazex-strapi.nx.mazex.agency/",
  });

  const fetchInvitationInfo = async (assessment_id: string) => {
    const invite = await api.get("/api/invite/" + assessment_id);

    const data = invite.data as InviteInfo;

    return data;
  };

  const fetchQuestion = async (assessment_id: string) => {
    const resp = await api.get(`/api/invite/${assessment_id}/ques`);

    const data = resp.data;

    return data as { questions: IQuestion[]; info: InviteInfo };
  };

  const submitAnswer = async (
    assessment_id: string,
    question_id: number,
    option_id: string | number
  ) => {
    const resp = await api.post(`/api/invite/${assessment_id}/submit`, {
      question_id,
      option_id,
    });
    return resp.data;
  };

  const completeAssessment = async (assessment_id: string) => {
    const resp = await api.post(`/api/invite/${assessment_id}/complete`);
    return resp.data;
  };

  const verifyEmailAndSaveName = async (
    assessment_id: string,
    email: string,
    name: string
  ) => {
    const resp = await api.post(`/api/invite/${assessment_id}/verify`, {
      email,
      name,
    });
    return resp.data;
  };

  return {
    fetchInvitationInfo,
    fetchQuestion,
    submitAnswer,
    verifyEmailAndSaveName,
    completeAssessment,
  };
})();
