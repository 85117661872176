import { Card } from "@supabase/ui";
import { Container } from "./App";

export const Ended = () => {
  return (
    <Container className="pt-8">
      <Card className="flex flex-col px-10 py-20">
        <p className="text-center">Your Assessment has finished</p>
        <p className="text-center mt-4">
          We have received yours answers . We will soon reach out to your for
          next steps.
        </p>
      </Card>
    </Container>
  );
};
