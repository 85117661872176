import { Button } from "@supabase/ui";
import { Container } from "./App";

export const NotFoundPage = () => {
  return (
    <Container
      style={{ maxWidth: 540 }}
      className="h-screen flex items-center flex-col justify-center"
    >
      <h3 className="text-3xl text-center">
        Oop's We couldn't find the page you are looking for.
      </h3>
      <Button className="m-4">Go To Home</Button>
    </Container>
  );
};
