import { MazexLogo } from "./Logo";
import { Container } from "./App";

export const HomePage = () => {
  return (
    <Container
      style={{ maxWidth: 540 }}
      className="h-screen flex items-center flex-col pt-36"
    >
      <MazexLogo width={200} className="mb-8" />
      <h3 className="text-3xl text-center">Mazex Assessments</h3>
    </Container>
  );
};
