import { Button, Card } from "@supabase/ui";
import { produce } from "immer";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { css, styled } from "styled-components";
import { ApiService, InviteInfo, IQuestion } from "./ApiService";
import { Container } from "./App";
import { PageState, timeLeft } from "./utils";

const Timer = ({
  info,
  onTimerEnd,
}: {
  info: InviteInfo;
  onTimerEnd: () => void;
}) => {
  const [duration, setDuration] = useState(() => timeLeft(info));

  useEffect(() => {
    const interVal = setInterval(() => {
      const left = timeLeft(info);
      if (left.minutes === 0 && left.seconds === 0) {
        onTimerEnd();
      }
      setDuration(left);
    }, 1000);
    return () => {
      clearInterval(interVal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center text-3xl font-semibold">
      <p>
        {duration.minutes.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </p>
      :
      <p>
        {duration.seconds.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </p>
    </div>
  );
};

const Progress = ({ percent = 0 }: { percent?: number }) => {
  return (
    <ProgressWrapper>
      <Bar progress={percent} />
    </ProgressWrapper>
  );
};
const ProgressWrapper = styled.div`
  width: 100%;
  display: block;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: 20px;
  background-color: #d9d9d9;
  /* box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); */
`;

const Bar = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  ${(props) => css`
    width: ${props.progress}%;
  `}
  background-color: rgba(36, 180, 126, 1);
`;

const QuesContainer = styled.div`
  & img {
    margin: 0 auto;
  }
`;

const Question = ({
  question,
  id,
  onSubmit,
  isLast,
  isFinishing,
}: {
  question: IQuestion["question"];
  id: number;
  isLast: boolean;
  isFinishing: boolean;
  onSubmit: (question_id: number, option_id: number) => void;
}) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  return (
    <>
      <QuesContainer className="pt-4 ">
        <div
          dangerouslySetInnerHTML={{
            __html: question.question,
          }}
        />
      </QuesContainer>
      <div className="py-3">
        {question.Option.map((option) => {
          return (
            <div
              key={option.id}
              className={
                "flex p-2 border-2 cursor-pointer mb-3 " +
                (option.id === selectedOption
                  ? "border-gray-500"
                  : "border-gray-300")
              }
              onClick={() => setSelectedOption(option.id)}
            >
              <input
                onChange={() => {}}
                checked={option.id === selectedOption}
                type={"radio"}
              />
              <span className="pl-3">{option.value}</span>
            </div>
          );
        })}
      </div>
      <div className="px-16">
        <Button
          onClick={() => {
            onSubmit(id, selectedOption as number);
          }}
          block
          disabled={isFinishing}
          className={"py-4"}
        >
          {isLast ? "Finish" : "Submit Answer"}
        </Button>
      </div>
    </>
  );
};

export const QuestionsPage = ({
  info,
  setInfo,
  setStatus,
}: {
  info: InviteInfo;
  setInfo: React.Dispatch<React.SetStateAction<InviteInfo | null>>;
  setStatus: React.Dispatch<React.SetStateAction<PageState>>;
}) => {
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [isReady, setIsReady] = useState(false);
  const [isFinishing, setIsFinishing] = useState(false);
  const { assessmentId } = useParams<{ assessmentId: string }>();

  const currentQuestionIndex = useMemo(() => {
    let i = 0;

    for (i = 0; i < questions.length; i++) {
      const ques = questions[i];
      if (ques.submitted_ans) {
        continue;
      } else {
        break;
      }
    }

    return i;
  }, [questions]);
  const currentQuestion = questions[currentQuestionIndex];
  const isLast = currentQuestionIndex === questions.length - 1;

  const totalCount = info.count;

  const fetchQuestions = async () => {
    const data = await ApiService.fetchQuestion(assessmentId as string);
    setQuestions(data.questions);
    setInfo(data.info);
    setIsReady(true);
  };

  useEffect(() => {
    fetchQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (question_id: number, option_id: number) => {
    if (isLast) {
      setIsFinishing(true);
      await ApiService.submitAnswer(
        assessmentId as string,
        question_id,
        option_id
      );
      completeAssessment();
    } else {
      ApiService.submitAnswer(assessmentId as string, question_id, option_id);
    }
    setQuestions((old) => {
      return produce(old, (draft) => {
        const index = old.findIndex((item) => item.id === question_id);
        draft[index].submitted_ans = option_id;
      });
    });
  };

  const completeAssessment = async () => {
    ApiService.completeAssessment(assessmentId as string);
    setInfo((old) =>
      old
        ? produce(old, (draft) => {
            draft.assessment_complete_time = new Date().toISOString();
          })
        : old
    );
    setStatus("finished");
  };

  return (
    <Container className="pt-8">
      {isReady && (
        <Card className="flex flex-col px-10 py-0">
          <div className="flex justify-end mb-3">
            <Timer info={info} onTimerEnd={completeAssessment} />
          </div>
          <Progress percent={((currentQuestionIndex + 1) * 100) / totalCount} />
          <div className="text-xs mt-2">
            Question{" "}
            <span className="font-bold">{currentQuestionIndex + 1}</span> /{" "}
            <span className="font-bold">{totalCount}</span>
          </div>
          {currentQuestion && (
            <Question
              onSubmit={handleSubmit}
              question={currentQuestion.question}
              id={currentQuestion.id}
              isLast={isLast}
              isFinishing={isFinishing}
            />
          )}
        </Card>
      )}
    </Container>
  );
};
