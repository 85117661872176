import { Button, Card, Input } from "@supabase/ui";
import { MazexLogo } from "./Logo";
import { Container } from "./App";
import { useState } from "react";
import { ApiService } from "./ApiService";
import { useParams } from "react-router-dom";

export const CollectInfo = ({
  onSuccess,
}: {
  onSuccess?: () => Promise<void>;
}) => {
  const [isNextStep, setNextStep] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const [inProgress, setInProgress] = useState(false);
  const verifyUser = async () => {
    setInProgress(true);
    try {
      await ApiService.verifyEmailAndSaveName(
        assessmentId as string,
        email,
        name
      );
      onSuccess && (await onSuccess());
    } catch (e) {
      // TODO: add alert if not verified
      setInProgress(false);
    }
  };

  return (
    <Container className="pt-8">
      <Card className="flex flex-col px-10 py-5">
        {!isNextStep && (
          <>
            {" "}
            <div className="flex justify-center mb-8">
              <MazexLogo width={200} />
            </div>
            <h4 className="text-center mt-4 mb-4 font-medium text-xl">
              Welcome to Mazex Assessment Center
            </h4>
            <p className="text-base">
              You have been invited to take assessments. This gives you the
              opportunity to demonstrate your abilities in a fair and objective
              way. Press Continue below to get started
            </p>
            <div className="flex flex-col items-center mt-24 px-20">
              <Button onClick={() => setNextStep(true)} block>
                Continue{" "}
              </Button>
            </div>
          </>
        )}
        {isNextStep && (
          <>
            <div className="flex justify-center mb-8">
              <MazexLogo width={200} />
            </div>
            <h4 className="text-center mt-4 mb-4 font-medium text-xl">
              Verify your information
            </h4>
            <p className="text-sm mb-3 text-center">
              Please enter or verify your information below:
            </p>

            <div className="flex flex-col px-20">
              <Input
                label="Email"
                type={"email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mb-6"
                required
              />
              <Input
                label="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type={"text"}
                required
              />

              <Button
                disabled={inProgress}
                className={"mt-8"}
                onClick={verifyUser}
                block
              >
                Verify{" "}
              </Button>
            </div>
          </>
        )}
      </Card>
    </Container>
  );
};
