import moment from "moment";
import { ApiService, InviteInfo } from "./ApiService";

export type PageState =
  | "unknown"
  | "nouser"
  | "inforeceived"
  | "inprogress"
  | "finished"
  | "ended";

export const getStatus = (info: InviteInfo): PageState => {
  if (!info.name) {
    return "nouser";
  }
  if (!info.assessment_start_time) {
    return "inforeceived";
  }
  const isFinished = info.assessment_complete_time;
  if (isFinished) {
    return "finished";
  }

  if (info.assessment_start_time) {
    const startTime = moment(info.assessment_start_time);
    const currentTime = moment();
    const duration = currentTime.diff(startTime, "second");
    if (duration > info.duration) {
      return "ended";
    } else {
      return "inprogress";
    }
  }
  return "unknown";
};

export const timeLeft = (
  info: InviteInfo
): { minutes: number; seconds: number } => {
  const duration = moment.duration(info.duration, "second");
  if (info.assessment_complete_time) {
    return { minutes: 0, seconds: 0 };
  }
  if (!info.assessment_start_time) {
    return { minutes: duration.minutes(), seconds: duration.seconds() };
  }

  const endTime = moment(info.assessment_start_time).add(duration);

  const currentTime = moment();
  const timeLeft = endTime.diff(currentTime, "second");
  if (timeLeft <= 0) {
    return {
      minutes: 0,
      seconds: 0,
    };
  } else {
    const duration = moment.duration(timeLeft, "second");
    return {
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }
};

export const fetchTestInfo = async (assessment_id: string) => {
  // TODO : fetch information from api
  // start_time
  // duration
  // name
  // end_time
  // no name -> nouser
  // null start  ->  inforeceived
  // start and not finished -> inprogeres fetch question
  //
  // end_time -> finished
  const info = await ApiService.fetchInvitationInfo(assessment_id);

  return {
    info,
    status: getStatus(info),
  };
};

export const waitFor = (time: number) => {
  return new Promise((res) => {
    setTimeout(res, time);
  });
};
