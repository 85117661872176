import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Helmet } from "react-helmet";
import { styled } from "styled-components";
import { NotFoundPage } from "./NotFoundPage";
import { HomePage } from "./HomePage";
import { AssessmentPage } from "./AssessmentPage";

export const Container = styled.div`
  max-width: 780px;
  width: 90%;
  margin: 0 auto;
`;

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  {
    path: "/assessment/link/:assessmentId",
    element: <AssessmentPage />,
  },
  { path: "*", element: <NotFoundPage /> },
]);

export default function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Aptitude Assessment</title>
      </Helmet>
      <RouterProvider router={router} />
    </>
  );
}
