import { Button, Card } from "@supabase/ui";
import moment from "moment";
import { InviteInfo } from "./ApiService";
import { Container } from "./App";
import { MazexLogo } from "./Logo";

export const ShowInfoAboutTest = ({
  info,
  startAssessment,
}: {
  info: InviteInfo;
  startAssessment: () => Promise<void>;
}) => {
  const duration = moment.duration(info.duration, "second");
  // const { assessmentId } = useParams<{ assessmentId: string }>();

  return (
    <Container className="pt-8">
      <Card className="flex flex-col px-10 py-5">
        <div className="flex justify-center mb-8">
          <MazexLogo width={200} />
        </div>
        <h4 className="text-center mt-4 mb-4 font-medium text-xl">
          Important Information
        </h4>
        <p className="text-sm mb-3">
          You will have {duration.minutes()} minutes to complete the assessment.
          It is unlikely that you will finish all {info.count} questions before
          time expires, but do your best to answer as many as you can. Please
          make sure you have enough uninterrupted time to complete the
          assessments.
        </p>
        <p className="text-sm mb-3">
          You are <b>NOT permitted</b> to use calculators or any other
          problem-solving device. Please ensure your device has a reliable
          internet connection. Please have a pen and paper with you when you
          take the assessment.
        </p>
        <p className="text-sm mb-3">
          Your time will begin as soon as you click the{" "}
          <b>"Start Assessment"</b> button.
        </p>
        <Button
          // disabled={inProgress}
          className={"mt-8"}
          onClick={startAssessment}
          block
        >
          Start Assessment{" "}
        </Button>
      </Card>
    </Container>
  );
};
